import { Inject, Injectable } from '@angular/core';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import { Amplify } from '@aws-amplify/core';
import { DOCUMENT } from '@angular/common';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from '@angular/router';
import { environment } from '../../../environments/environment';
import { from, Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CONSTANTS } from '../CONSTANTS';

@Injectable({
    providedIn: 'root',
})
export class AuthenticationService implements CanActivate {
    private user: (CognitoUser & { challengeParam: { sms: string } }) | null =
        null;
    private identifier = '';
    private window: Window | null;
    userAction: UserAction | undefined;
    private token: string | undefined = '';
    private isInitialized = false;
    userId: string | undefined | null;

    authConfig: {
        mechanism: {
            attribute: string;
            label: string;
            prefix: string;
            pattern: string;
            type: string;
            maxLength: string;
        };
    } = {
        mechanism: {
            label: 'mobile number',
            prefix: '+91',
            pattern: '^[5-9][0-9]{9}$',
            type: 'tel',
            attribute: 'mobile_number',
            maxLength: '10',
        },
    };

    constructor(@Inject(DOCUMENT) document: Document) {
        Amplify.configure(environment.auth.amplify);
        this.window = document.defaultView;
        const emailAuthMechanism = environment.auth.mechanism === 'email';
        if (emailAuthMechanism) {
            this.authConfig = {
                mechanism: {
                    label: 'email address',
                    type: 'email',
                    attribute: 'email',
                    prefix: '',
                    pattern:
                        '^(([^<>()[\\]\\\\.,;:\\s@\\"]+(\\.[^<>()[\\]\\\\.,;:\\s@\\"]+)*)|(\\".+\\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
                    maxLength: '320',
                },
            };
        }
    }

    private static async isAuthenticated() {
        try {
            const session = await Auth.currentSession();
            return session.isValid();
        } catch (e) {
            console.warn(e);
        }
        return false;
    }

    private static async isNotAuthenticated() {
        return !(await this.isAuthenticated());
    }

    fetchToken(): Observable<string | undefined> {
        return from(Auth.currentSession()).pipe(
            map(session => {
                this.isInitialized = true;
                this.token = session?.getAccessToken()?.getJwtToken();
                return this.token;
            }),
            catchError(err => {
                if (err !== 'No current user') {
                    console.error(err);
                }
                return of(undefined);
            })
        );
    }

    getUserId() {
        let username = localStorage.getItem('username');
        if (!username) {
            this.userId = localStorage.getItem(CONSTANTS.GUEST_ID_HEADER_NAME);
        } else {
            this.userId = username;
        }
        return this.userId;
    }

    getAuthToken(): Observable<string | undefined> {
        if (!this.isInitialized) {
            return this.fetchToken();
        }
        if (this.isTokenExpired()) {
            return this.fetchToken();
        }
        return of(this.token);
    }

    async generateOTP(identifier: string, channel?: string) {
        this.identifier = identifier;
        try {
            if (channel) {
                await this.preAuthentication(identifier, channel);
            }
            await this.signIn(identifier);
            this.userAction = UserAction.LOGIN;
        } catch (e) {
            try {
                if (this.authConfig.mechanism.type === 'email') {
                    await this.signUp(identifier, true);
                } else {
                    await this.signUp(identifier);
                }

                await this.signIn(identifier);
                this.userAction = UserAction.SIGN_UP;
            } catch (e) {
                console.error(e);
            }
        }
    }

    //  TODO return error message in case of failure
    async verifyOTP(otp: string): Promise<{
        verified: boolean;
        action: UserAction | undefined;
        token: string;
    }> {
        let userSession;
        try {
            this.user = await Auth.sendCustomChallengeAnswer(this.user, otp);

            userSession = await Auth.userSession(this.user);
            this.token = userSession?.getAccessToken()?.getJwtToken();
        } catch (err) {
            console.error(err);
            return {
                verified: false,
                action: this.userAction,
                token: '',
            };
        }
        // @ts-ignore
        localStorage.setItem('username', this.user?.attributes.sub as string);
        return {
            verified: userSession.isValid(),
            action: !!this.userAction ? this.userAction : undefined,
            token: userSession.getAccessToken().getJwtToken(),
        };
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return AuthenticationService.isNotAuthenticated();
    }

    private async signIn(identifier: string) {
        this.user = await Auth.signIn(identifier);
    }

    private async signUp(identifier: string, isEmail?: boolean) {
        let params = null;
        if (isEmail) {
            params = {
                username: identifier,
                password: this.generateRandomSecureString(),
                attributes: { email: identifier },
            };
        } else {
            params = {
                username: identifier,
                password: this.generateRandomSecureString(),
            };
        }

        await Auth.signUp(params);
    }

    private generateRandomSecureString() {
        const randomValues = new Uint8Array(30);
        this.window?.crypto.getRandomValues(randomValues);
        return Array.from(randomValues)
            .map(value => value.toString(16).padStart(2, '0'))
            .join('');
    }

    getUserMobileNo() {
        return this.identifier;
    }

    isTokenExpired() {
        try {
            if (this.token == null) {
                return false;
            }
            let tokenPart = this.token.split('.')[1];
            tokenPart = atob(tokenPart);
            if (JSON.parse(tokenPart)?.exp <= new Date().getTime()) {
                return true;
            }
        } catch (e) {
            return false;
        }
    }

    getAuthConfig() {
        return this.authConfig;
    }

    private async preAuthentication(identifier: string, channel: string) {
        try {
            const signInOpts = {
                username: identifier,
                password: this.generateRandomSecureString(),
                authFlow: 'CUSTOM_AUTH',
                authParameters: {
                    USERNAME: identifier,
                },
                validationData: {
                    otpType: channel,
                },
            };

            await Auth.signIn(signInOpts);
            console.log('PreAuthentication executed successfully.');
        } catch (error) {
            console.error('PreAuthentication failed:', error);
        }
    }
}

export enum UserAction {
    LOGIN,
    SIGN_UP,
}
